import { useEffect } from 'react';
import {
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Tab,
  Stack,
  FormControl,
  Textarea,
  Input,
  FormLabel,
} from '@chakra-ui/react';

import { Detail, Details } from './Details';

interface Props {
  formValues: any;
  setFormValues: (value: any) => void;
  handleChange: (e: any) => void;
  details: Detail[];
  setDetails: React.Dispatch<React.SetStateAction<Detail[]>>;
  detailsRo: Detail[];
  setDetailsRo: React.Dispatch<React.SetStateAction<Detail[]>>;
  detailsRu: Detail[];
  setDetailsRu: React.Dispatch<React.SetStateAction<Detail[]>>;
}

const formatDetails = (details: string[] | undefined) =>
  details?.length
    ? details.map((detail: string) => ({
        id: Math.random().toString(),
        value: detail,
      }))
    : [];

export const LanguagesFormTabs = ({
  formValues,
  setFormValues,
  handleChange,
  details,
  setDetails,
  detailsRo,
  setDetailsRo,
  detailsRu,
  setDetailsRu,
}: Props) => {
  useEffect(() => {
    setDetails(formatDetails(formValues?.details));
    setDetailsRo(formatDetails(formValues?.ro?.details));
    setDetailsRu(formatDetails(formValues?.ru?.details));
  }, [formValues?.details, formValues?.ro?.details, formValues?.ru?.details]);

  return (
    <Tabs variant="soft-rounded" colorScheme="orange">
      <TabList pb={2}>
        <Tab>English</Tab>
        <Tab>Romanian</Tab>
        <Tab>Russian</Tab>
      </TabList>
      <TabPanels>
        <TabPanel ps={0}>
          <Stack spacing={6}>
            <FormControl id="name" isRequired>
              <FormLabel>Name (EN, default)</FormLabel>
              <Input type="name" name="name" value={formValues.name} onChange={handleChange} />
            </FormControl>
            <FormControl id="subtitle">
              <FormLabel>Subtitle (EN, default)</FormLabel>
              <Input
                type="subtitle"
                name="subtitle"
                value={formValues.subtitle}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="description">
              <FormLabel>Description (EN, default)</FormLabel>
              <Textarea
                name="description"
                value={formValues.description}
                onChange={handleChange}
                height="150px"
                resize="vertical"
              />
            </FormControl>
            <FormControl id="details">
              <FormLabel>Details (EN, default)</FormLabel>
              <Details details={details} setDetails={setDetails} />
            </FormControl>
          </Stack>
        </TabPanel>
        <TabPanel ps={0}>
          <Stack spacing={6}>
            <FormControl id="name-ro">
              <FormLabel>Name (RO)</FormLabel>
              <Input
                type="name"
                name="name-ro"
                value={formValues.ro?.name}
                onChange={(e) =>
                  setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    ro: {
                      ...prevFormValues.ro,
                      name: e.target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl id="subtitle-ro">
              <FormLabel>Subtitle (RO)</FormLabel>
              <Input
                type="subtitle"
                name="subtitle-ro"
                value={formValues.ro?.subtitle}
                onChange={(e) =>
                  setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    ro: {
                      ...prevFormValues.ro,
                      subtitle: e.target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl id="description-ro">
              <FormLabel>Description (RO)</FormLabel>
              <Textarea
                name="description-ro"
                value={formValues.ro?.description}
                onChange={(e) =>
                  setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    ro: {
                      ...prevFormValues.ro,
                      description: e.target.value,
                    },
                  }))
                }
                height="150px"
                resize="vertical"
              />
            </FormControl>
            <FormControl id="details-ro">
              <FormLabel>Details (RO)</FormLabel>
              <Details details={detailsRo} setDetails={setDetailsRo} />
            </FormControl>
          </Stack>
        </TabPanel>
        <TabPanel ps={0}>
          <Stack spacing={6}>
            <FormControl id="name-ru" isRequired={false}>
              <FormLabel>Name (RU)</FormLabel>
              <Input
                type="name"
                name="name-ru"
                value={formValues.ru?.name}
                onChange={(e) =>
                  setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    ru: {
                      ...prevFormValues.ru,
                      name: e.target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl id="subtitle-ru" isRequired={false}>
              <FormLabel>Subtitle (RU)</FormLabel>
              <Input
                type="subtitle"
                name="subtitle-ru"
                value={formValues.ru?.subtitle}
                onChange={(e) =>
                  setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    ru: {
                      ...prevFormValues.ru,
                      subtitle: e.target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl id="description-ru">
              <FormLabel>Description (RU)</FormLabel>
              <Textarea
                name="description-ru"
                value={formValues.ru?.description}
                onChange={(e) =>
                  setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    ru: {
                      ...prevFormValues.ru,
                      description: e.target.value,
                    },
                  }))
                }
                height="150px"
                resize="vertical"
              />
            </FormControl>
            <FormControl id="details-ro">
              <FormLabel>Details (RU)</FormLabel>
              <Details details={detailsRu} setDetails={setDetailsRu} />
            </FormControl>
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
