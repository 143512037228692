export const slugify = (text: string) =>
  text?.length && text.replace(/\s+/g, '-').replace(/[/']/g, '').toLowerCase();

export const formatCategory = (category: string) =>
  category?.length &&
  (category === 'all'
    ? 'All categories'
    : category.charAt(0).toUpperCase() + category.replace(/-/g, ' ').slice(1));

export const titleize = (text: string) =>
  text
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const truncate = (text: string, length: number) =>
  text?.length > length ? text.slice(0, length) + '…' : text;

export const generateId = () => Math.random().toString(36).substr(2, 3);
