import { Box, Button, Container, Heading, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ProductForm } from '../components';

const AddProduct = () => (
  <Container maxW="8xl" mx="auto" minH={'calc(100vh - 112px)'} pb={20}>
    <HStack mb={8} justifyContent="space-between">
      <Heading size="lg">Add product</Heading>
      <Link to="/catalog">
        <Button variant="ghost" colorScheme="orange" size="md">
          Cancel
        </Button>
      </Link>
    </HStack>
    <Box bg="white" p={6} border="1px" borderColor="gray.100">
      <ProductForm />
    </Box>
  </Container>
);

export default AddProduct;
