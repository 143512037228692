import { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, IconButton, List, ListItem, Stack } from '@chakra-ui/react';
import { AutoResizeTextarea } from './AutoResizeTextarea';

export interface Detail {
  id: string;
  value: string;
}

interface DetailsProps {
  details: Detail[];
  setDetails: any;
}

export const Details = ({ details, setDetails }: DetailsProps) => {
  const [detail, setDetail] = useState('');

  const handleAdd = () => {
    if (detail.trim().length > 0) {
      setDetails((prevDetails: any) => [
        ...prevDetails,
        {
          id: Math.random().toString(),
          value: detail as string,
        },
      ]);
      setDetail('');
    }
  };

  return (
    <Stack spacing={6}>
      {details && details?.length > 0 && (
        <List spacing={3}>
          {details?.map(({ id, value }: Detail) => (
            <ListItem
              key={id}
              boxShadow="sm"
              py={2}
              px={4}
              borderLeft="2px"
              borderColor={'orange.500'}
              rounded="sm"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                contentEditable
                outline="none"
                whiteSpace="pre-line"
                color={'gray.600'}
                _hover={{ color: 'gray.800' }}
                _focus={{ color: 'gray.800' }}
                title="Click to edit"
                onBlur={(e: React.FocusEvent<HTMLDivElement>) => {
                  const newDetails = details.map((detail: Detail) =>
                    detail.id === id ? { ...detail, value: e.target.innerText } : detail,
                  );
                  setDetails(newDetails);
                }}
              >
                {value}
              </Box>
              <IconButton
                icon={<CloseIcon />}
                size="xs"
                variant="ghost"
                colorScheme="orange"
                aria-label="Delete"
                title="Delete"
                ms={2}
                onClick={() => setDetails(details.filter((detail: Detail) => detail.id !== id))}
              />
            </ListItem>
          ))}
        </List>
      )}

      <Stack>
        <AutoResizeTextarea
          name="detail"
          placeholder="Type..."
          value={detail}
          onChange={(e: any) => setDetail(e.target.value)}
        />
        <Button onClick={handleAdd}>Add detail</Button>
      </Stack>
    </Stack>
  );
};
