import { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';

import { Navbar } from './Navbar';

export const Layout = ({ children }: PropsWithChildren) => (
  <Box bg="gray.50" minH="100vh">
    <Navbar />
    {children}
  </Box>
);
