import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import Table from 'rc-table';
import debounce from 'lodash.debounce';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import { EditIcon, ViewIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';

import { catalogApi } from '../firebase/catalog';
import { Product, ProductStatus } from '../types/product';
import { formatCategory, truncate } from '../utils/text';
import { useUser } from '../hooks';

import { CategorySelect, Loader } from '../components';
import { useMemo, useCallback, useState } from 'react';

const useProductFilters = (
  products: Product[] | undefined,
  searchTerm: string,
  category: string,
  status: string,
) => {
  return useMemo(() => {
    if (!products) return [];

    return products
      .filter(({ name }) => !searchTerm || name?.toLowerCase()?.includes(searchTerm.toLowerCase()))
      .filter(
        ({ category: pCategory }) =>
          category === 'all' || pCategory?.toLowerCase()?.includes(category.toLowerCase()),
      )
      .filter(
        ({ status: pStatus }) =>
          status === 'all' ||
          (pStatus === ProductStatus.Draft && status === ProductStatus.Draft) ||
          (pStatus !== ProductStatus.Draft && status !== ProductStatus.Draft),
      )
      .sort((a, b) => {
        if (!a.updatedAt || !b.updatedAt) return !a.updatedAt ? 1 : -1;
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });
  }, [products, searchTerm, category, status]);
};

const createTableColumns = (
  onStatusChange: (product: Product, isPublic: boolean) => void,
  onDelete: (productName: string) => void,
) => [
  {
    width: 20,
    title: 'Public',
    render: (record: Product) => {
      return (
        <Checkbox
          id={record.id}
          name={record.id}
          colorScheme="orange"
          size="lg"
          isChecked={!(record.status === ProductStatus.Draft)}
          onChange={(e) => onStatusChange(record, e.target.checked)}
        />
      );
    },
  },
  {
    width: 20,
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    width: 90,
    render: (record: Product) => (
      <Flex
        height="70px"
        width="70px"
        alignItems="middle"
        justify="center"
        bg="gray.100"
        p={2}
        rounded="md"
      >
        <Box height={0}>
          <Link to={`/catalog/edit/${record.id}`}>
            <Image
              src={!record?.isFromFirebase ? `http://rental.brmg.md/${record.img}` : record.img}
              alt="product"
              boxShadow="md"
              maxHeight="54px"
            />
          </Link>
        </Box>
      </Flex>
    ),
  },
  {
    title: 'Name',
    render: (record: Product) => (
      <Link to={`/catalog/edit/${record.id}`}>{truncate(record.name, 50)}</Link>
    ),
  },
  {
    title: 'Availability',
    dataIndex: 'availability',
    key: 'availability',
  },
  {
    title: 'Stock',
    dataIndex: 'maxQty',
    key: 'maxQty',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Relevance',
    dataIndex: 'relevance',
    key: 'relevance',
    render: (text: number) => text || 'N/A',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (text: string) => formatCategory(text),
  },
  {
    render: (record: Product) => {
      const isDraft = record.status === ProductStatus.Draft;

      return (
        <HStack align="center" justify="end">
          <a
            href={!isDraft ? `https://rental.brmg.md/catalog/product?id=${record.id}` : '#'}
            target={!isDraft ? '_blank' : '_self'}
            rel="noreferrer"
            aria-disabled={isDraft}
          >
            <Button size="sm" variant="outline" width="32px" title="View" disabled={isDraft}>
              <ViewIcon />
            </Button>
          </a>
          <Link to={`/catalog/edit/${record.id}`}>
            <Button size="sm" variant="outline" width="32px" title="Edit">
              <EditIcon />
            </Button>
          </Link>
          <Button
            size="sm"
            variant="outline"
            width="32px"
            title="Delete"
            onClick={() =>
              confirm('Are you sure you want to delete this product?') && onDelete(record.name)
            }
          >
            <DeleteIcon />
          </Button>
        </HStack>
      );
    },
  },
];

const Catalog = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { idToken } = useUser();

  const [searchTerm = '', setSearchTerm] = useQueryParam('search', StringParam);
  const [category = 'all', setCategory] = useQueryParam('category', StringParam);
  const [status = 'all', setStatus] = useQueryParam('status', StringParam);

  const [searchValue, setSearchValue] = useState('');

  // Implement stale time and caching strategy
  const { data: products, isLoading } = useQuery(['catalog'], catalogApi.get, {
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnWindowFocus: false, // Disable automatic refetch on window focus
  });

  // Use optimized filtering with custom hook
  const filteredProducts = useProductFilters(
    products,
    searchTerm as string,
    category as string,
    status as string,
  );

  const { mutate: setItemsMutation } = useMutation(catalogApi.setProducts, {
    onSuccess: () => {
      queryClient.invalidateQueries(['catalog']);
    },
  });

  // Memoize callback functions
  const handleStatusChange = useCallback(
    (product: Product, isPublic: boolean) => {
      const newProducts = products?.map((p: Product) =>
        p.id === product.id
          ? { ...p, status: isPublic ? ProductStatus.Public : ProductStatus.Draft }
          : p,
      );

      setItemsMutation(
        { products: newProducts as Product[], idToken },
        {
          onSuccess: () => {
            toast({
              title: 'Product status updated successfully',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          },
        },
      );
    },
    [products, setItemsMutation, idToken, toast],
  );

  const handleDelete = useCallback(
    (productName: string) => {
      if (!productName || !products) return;

      const newProducts = products
        .filter((product) => product.name !== productName)
        .map((product: Product) =>
          product?.relatedProducts?.includes(productName)
            ? {
                ...product,
                relatedProducts: product.relatedProducts.filter(
                  (relatedProduct: string) => relatedProduct !== productName,
                ),
              }
            : product,
        );

      setItemsMutation(
        { products: newProducts, idToken },
        {
          onSuccess: () => {
            toast({
              title: 'Product deleted successfully',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          },
        },
      );
    },
    [products, setItemsMutation, idToken, toast],
  );

  // Memoize columns to prevent unnecessary re-renders
  const columns = useMemo(
    () => createTableColumns(handleStatusChange, handleDelete),
    [handleStatusChange, handleDelete],
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchTerm(value.toLowerCase());
    },
    [setSearchTerm],
  );

  return (
    <Container maxW="8xl" mx="auto" minH={'calc(100vh - 112px)'}>
      <HStack mb={8} justifyContent="space-between">
        <Heading size="lg">Catalog Products</Heading>
        <Link to="/catalog/new">
          <Button colorScheme="orange" size="md">
            Add Product
          </Button>
        </Link>
      </HStack>
      <Box bg="white" p={6} border="1px" borderColor="gray.100">
        <Flex mb={4} direction={{ base: 'column', lg: 'row' }}>
          <HStack
            justifyContent={{ base: 'space-between', lg: 'start' }}
            mr={{ base: 0, lg: 6 }}
            mb={{ base: 4, lg: 0 }}
          >
            <InputGroup width={{ base: '80%', lg: 'auto' }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  debounce(() => handleSearch(e.target.value), 500)();
                }}
                placeholder="Search by name"
                rounded="lg"
                width={{ base: '100%', lg: '400px' }}
              />
            </InputGroup>
            <Button
              colorScheme="orange"
              variant="outline"
              type="button"
              onClick={() => {
                setSearchValue('');
                setSearchTerm('');
              }}
            >
              Clear
            </Button>
          </HStack>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <CategorySelect
              setCategory={(e: any) => setCategory(e.target.value.toLowerCase())}
              value={category as string}
              width={{ base: '100%', md: '50%', lg: 'auto' }}
              mb={{ base: 4, md: 0 }}
            />
            <Select
              value={status as string}
              onChange={(e) => setStatus(e.target.value.toLowerCase())}
              width={{ base: '100%', md: '50%', lg: 'auto' }}
              ml={{ base: 0, md: 6 }}
              mt={{ base: 4, md: 0 }}
            >
              <option value="all">All statuses</option>
              <option value="draft">Draft</option>
              <option value="public">Public</option>
            </Select>
          </Flex>
        </Flex>
        {isLoading ? (
          <Flex justify="center" align="center" height="300px">
            <Loader />
          </Flex>
        ) : (
          <Table columns={columns} data={filteredProducts} />
        )}
      </Box>
    </Container>
  );
};

export default Catalog;
