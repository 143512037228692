import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from '../hooks';

const PublicRoute = () => {
  const { logged } = useUser();

  return !logged ? <Outlet /> : <Navigate to="/catalog" />;
};

export default PublicRoute;
