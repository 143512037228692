export interface Product {
  category: ProductCategory;
  description: string;
  name: string;
  details?: string[] | null;
  relatedProducts?: string[] | null;
  img: string;
  ro: {
    name: string;
    description: string;
    details?: string[] | null;
  };
  ru: {
    name: string;
    description: string;
    details?: string[] | null;
  };
  status: ProductStatus;
  maxQty?: number | string | null;
  isFromFirebase?: boolean;
  createdAt?: string | Date;
  updatedAt?: string | Date;
  id?: string;
  price?: number;

  relevance?: number; // 0-5, 0 - not relevant, 5 - very relevant, undefined - not rated
}

export enum ProductCategory {
  Cameras = 'cameras',
  Cars = 'cars',
  Sound = 'sound',
  Accessories = 'accessories',
  Lenses = 'lenses',
  VideoControl = 'video-control',
  Lightning = 'lightning',
  BaseCamp = 'base-camp',
  CameraMotion = 'camera-motion',
  PowerDistribution = 'power-distribution',
  ElectricEquipment = 'electric-equipment',
  SFX = 'sfx',
  Grip = 'grip',
  Others = 'others',
  Filters = 'filters',
}

export enum ProductStatus {
  Draft = 'draft',
  Public = 'public',
}

export enum ProductAvailability {
  Available = 'available',
  Unavailable = 'unavailable',
  Soon = 'soon',
}
