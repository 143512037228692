import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Img,
  Text,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import { useUser } from '../hooks';

const Links = [{ text: 'Catalog', path: '/catalog' }];

const NavLink = ({ children, path }: { children: ReactNode; path: string }) => (
  <Link to={path}>
    <a>{children}</a>
  </Link>
);

export const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onLogOut } = useUser();

  return (
    <Box bg="white" boxShadow="sm" px={4} mb={12}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={'center'}>
          <Link to="/catalog">
            <Img src="https://rental.brmg.md/images/logo.svg" width="96px" />
          </Link>
          <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
            {Links.map(({ text, path }) => (
              <NavLink key={text} path={path}>
                {text}
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              as={Button}
              rounded="md"
              variant="ghost"
              cursor={'pointer'}
              minW={0}
              px={{ base: 0, md: 4 }}
              py={{ base: 0, md: 2 }}
            >
              <HStack spacing={{ base: 'none', md: 4 }}>
                <Text color="orange.800" display={{ base: 'none', md: 'block' }}>
                  admin
                </Text>
                <Avatar size={'sm'} name="A" bg={'orange.500'} />
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onLogOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link, i) => (
              <NavLink key={i} path={link.path}>
                {link.text}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};
