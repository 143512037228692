import { Product } from '../types/product';

const { REACT_APP_FIREBASE_DATABASE_URL: dbUrl } = process.env;

export const catalogApi = {
  get: async () => {
    const response = await fetch(`${dbUrl}/catalog2.json`);
    const data = await response.json();
    const dataArray = Object.keys(data).map((key) => ({ id: key, ...data[key] }));

    return dataArray;
  },

  getById: async (id: string) => {
    const response = await fetch(`${dbUrl}/catalog2.json`);
    const data = await response.json();
    const product = Object.keys(data)
      .map((key) => ({ id: key, ...data[key] }))
      .find((product) => product.id === id);

    return product;
  },

  add: async ({ product, idToken }: { product: Product; idToken?: string }) => {
    const response = await fetch(`${dbUrl}/catalog2.json?auth=${idToken}`, {
      method: 'POST',
      body: JSON.stringify(product),
    });
    const data = await response.json();

    return data;
  },

  delete: async ({ id, idToken }: { id: string; idToken?: string }) => {
    const response = await fetch(`${dbUrl}/catalog2/${id}.json?auth=${idToken}`, {
      method: 'DELETE',
    });
    const data = await response.json();

    return data;
  },

  update: async ({ product, idToken }: { product: Product; idToken?: string }) => {
    const response = await fetch(`${dbUrl}/catalog2/${product.id}.json?auth=${idToken}`, {
      method: 'PATCH',
      body: JSON.stringify(product),
    });
    const data = await response.json();

    return data;
  },

  setProducts: async ({ products, idToken }: { products: Product[]; idToken?: string }) => {
    const response = await fetch(`${dbUrl}/catalog2.json?auth=${idToken}`, {
      method: 'PUT',
      body: JSON.stringify(products),
    });
    const data = await response.json();

    return data;
  },
};
