import { Flex, Heading, Image, Stack } from '@chakra-ui/react';

import { LogInForm } from '../components';

const LogInPage = () => (
  <Flex minH={'100vh'} align={'center'} justify={'center'} bg="gray.50">
    <Stack spacing={8} mx={'auto'} maxW={'xl'} mt="-100px" px={4}>
      <Image src="logo512.png" width="100px" alt="logo" mx="auto" />
      <Heading fontSize={'4xl'} textAlign="center">
        Sign in to BR Rental Dashboard
      </Heading>
      <LogInForm />
    </Stack>
  </Flex>
);

export default LogInPage;
