import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

import LogInPage from '../pages/LogInPage';
import Catalog from '../pages/Catalog';
import AddProduct from '../pages/AddProduct';
import EditProduct from '../pages/EditProduct';

const AppRouter = () => (
  <Routes>
    <Route element={<PublicRoute />}>
      <Route path="/" element={<LogInPage />} />
      <Route path="/login" element={<LogInPage />} />
    </Route>
    <Route element={<ProtectedRoute />}>
      <Route path="/catalog" element={<Catalog />} />
      <Route path="/catalog/new" element={<AddProduct />} />
      <Route path="/catalog/edit/:id" element={<EditProduct />} />
    </Route>
    <Route path="*" element={<div>Not found</div>} />
  </Routes>
);

export default AppRouter;
