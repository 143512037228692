import { createContext, useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

import app from '../firebase';
import { Loader } from '../components';
import { useToast } from '@chakra-ui/react';

interface UserContextProps {
  user: any;
  logged: boolean;
  idToken?: string;
  setLogged: (logged: boolean) => void;
  onLogIn: (email: string, password: string) => void;
  onLogOut: () => void;
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  logged: false,
  idToken: undefined,
  setLogged: () => {},
  onLogIn: () => {},
  onLogOut: () => {},
});

const auth = getAuth(app);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useToast();

  const [logged, setLogged] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const errorToast = (message: string) =>
    toast({
      title: 'Error',
      description: message,
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: 'top-right',
    });

  const onLogIn = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIdToken(idToken);
    } catch (err: any) {
      errorToast(err.message);
    }
  };

  const onLogOut = async () => {
    try {
      await auth.signOut();
      setIdToken(undefined);
    } catch (err: any) {
      errorToast(err.message);
    }
  };

  onAuthStateChanged(auth, async (user) => {
    setLogged(!!user);
    setUser(user);
    setIsLoading(false);

    if (user) {
      const idToken = await auth.currentUser?.getIdToken();
      setIdToken(idToken);
    }
  });

  return (
    <UserContext.Provider value={{ logged, user, idToken, setLogged, onLogIn, onLogOut }}>
      {isLoading ? <Loader /> : children}
    </UserContext.Provider>
  );
};

export default UserProvider;
