import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from '../hooks';
import { Layout } from '../components';

const ProtectedRoute = () => {
  const { logged } = useUser();

  return logged ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
