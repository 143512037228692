/* eslint-disable import/no-unresolved */
import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { parse, stringify } from 'query-string';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';

import UserProvider from './context/User';
import AppRouter from './routes/AppRouter';
import { ScrollToTop } from './components';
import './styles/index.scss';

const queryClient = new QueryClient();

const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <UserProvider>
          <BrowserRouter>
            <ScrollToTop>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <AppRouter />
              </QueryParamProvider>
            </ScrollToTop>
          </BrowserRouter>
        </UserProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </StrictMode>,
);
